<template>
  <div class="page-home-b-lieve-banner">
    <div class="page-home-b-lieve-banner__wrap">
      <!-- Cover -->
      <div
        v-if="props.content.linkPreviewHomeBannerManifesto"
        class="page-home-b-lieve-banner__cover"
      >
        <!-- Video -->
        <WidgetVideo
          class="is-cover"
          :videoUrl="props.content.linkPreviewHomeBannerManifesto"
          :controls="false"
          :loop="true"
          :autoplay="true"
          :muted="true"
        />
      </div>
    </div>

    <!-- Content -->
    <div class="page-home-b-lieve-banner__content" :class="{ '--dark-text': !props.content.whiteTextHomeBannerManifesto }">
      <div class="page-home-b-lieve-banner__content__wrap"
      >
        <!-- Text -->
        <div
          v-if="props.content.linkPreviewHomeBannerTitleManifesto"
          v-html="props.content.linkPreviewHomeBannerTitleManifesto"
          class="text-xl-1"
        />
        <!-- Cta -->
        <nuxt-link
          v-if="props.content.linkPreviewHomeBannerCtaManifesto"
          class="button-round-1 m-top-24px"
          :to="localePath({ name: 'studio' })"
          :class="!props.content.whiteTextHomeBannerManifesto ? 'is-transparent-blue' : 'is-transparent'"
          ><span>{{
            props.content.linkPreviewHomeBannerCtaManifesto
          }}</span></nuxt-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PagesHomeBLieveBanner',
}
</script>

<script setup>
const props = defineProps({
  content: Object,
})
</script>

<style lang="scss">
@import './style.scss';
</style>
